.contact-form {
    margin: 30px auto;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: rgb(1, 25, 131);
    box-sizing: border-box;
    color:#ddd;
  }

  .contact-form ul {
    text-align: left;
  }

  .contact-form a {
    color: #ddd;
  }

.g-recaptcha{
  display: inline-block;
  margin-bottom: 1em;
}